<template>
  <v-container fluid>
    <div class="row row--dense">
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between " >
          <div class="d-flex bordered qp-tab-nav">
            <div class="nv_item">
              <v-autocomplete
                  :items="[]"
                  item-value="venue_service_id"
                  item-text="name"
                  outlined
                  multiple
                  placeholder="Services"
                  background-color="rgba(79, 174, 175, 0.1)"
                  hide-details
                  style="width: 150px;"
                  class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
                  :height="46"
                  dense
                  color="#4FAEAF"
                  item-color="#4FAEAF"
                  disabled
              >
              </v-autocomplete>
            </div>
            <MembershipTopTab :dashboardClass="false" :scheduleClass="true" :schedulesNewClass="false" :analyticsClass="false" :salesClass="false" :membersClass="false"/>
          </div>
        </div>
      </v-col>
    </div>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>
    <div class="md-card md-theme-default mt-8 shadow rounded-5">
      <div class="md-card-content md-card-table">
        <div>
          <div class="d-flex justify-space-between align-center">
            <SvgIcon class="text-2xl font-semibold" text="Membership Attendance Log" >
            </SvgIcon>
            <v-spacer/>

            <div class="d-flex align-center gap-x-4 mt-2">
              <v-select
                  v-model="perPage"
                  :items="[10, 15, 25, 50]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0 "
                  hide-details
                  outlined
                  @change="getSchedules"
                  style="max-width: 120px"
                  placeholder="Per Page"
                  dense
              ></v-select>
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  filled
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      outlined
                      dense
                      class="q-text-field shadow-0"
                      v-model="date1Formatted"
                      readonly
                      v-on="on"
                      flat
                      hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.from_date"
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  filled
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      class="q-text-field shadow-0"
                      dense
                      v-model="date2Formatted"
                      outlined
                      readonly
                      v-on="on"
                      flat
                      hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.to_date"
                    @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn class="mr-2 bg-blue text-white" height="40"  elevation="0" @click="getSchedules">
                Go
              </v-btn>
            </div>
            <v-btn
                class="export-button mt-2"
                elevation="0"
                height="40"
                v-if="
                        checkExportPermission(
                          $modules.memberships.schedule.slug
                        )
                      "
                @click="reportDownload"
            >
              <SvgIcon text="Export" >
                <template v-slot:icon>
                  <ExportIcon/>
                </template>
              </SvgIcon>
            </v-btn>
          </div>
          <br><br/>
          <div class="table-responsive">
            <table class="table border-collapse text-center">
              <thead >
              <tr class="opacity-70 tr-neon tr-rounded text-center">
                <th class="">
                  Check In
                </th>
                <th class="">
                  Check Out
                </th>
                <th
                    v-if="
                            checkReadPermission(
                              $modules.memberships.unrestricted_view.slug
                            )
                          "
                >
                  <div

                  >
                    <div class="">Scanner</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          label="Cashier"
                          v-model="searchParams.cashier_name"
                          item-value="name"
                          item-text="name"
                          :items="cashier_names"
                          :loading="isLoading"
                          :search-input.sync="cashier_name"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          hide-details
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th >
                  <div>Customer</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Customer"
                        v-model="searchParams.name"
                        item-value="name"
                        item-text="name"
                        :items="names"
                        :loading="isLoading"
                        :search-input.sync="name"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    ></v-autocomplete>
                  </div>
                </th>
                <th class="">
                  <div>Age</div>
                  <div class="search_column">
                    <v-select
                        v-model="searchParams.age"
                        :items="AgeRange"
                        @change="ageChange"
                        label="Age"
                        ref="ageRef"
                        solo
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <div style="width: 150px">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-text-field
                                    label="Start Age"
                                    class="shrink"
                                    v-model="fromAge"
                                    append-icon="mdi-keyboard_arrow_down"
                                ></v-text-field>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <v-text-field
                                    label="End Age"
                                    class="shrink"
                                    v-model="toAge"
                                    append-icon="mdi-keyboard_arrow_down"
                                ></v-text-field>
                              </v-list-item-subtitle>
                              <v-btn
                                  small
                                  color="primary"
                                  @click="ageOkClick"
                              >Ok</v-btn
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </th>
                <th>
                  <div class="">Membership</div>
                  <div class="search_column">
                    <v-select
                        solo
                        dense
                        label="Memberships"
                        :items="memberships"
                        item-text="name"
                        item-value="membership_id"
                        multiple
                        v-model="searchParams.memberships"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    >
                      <template
                          v-if="
                                    searchParams.memberships != null &&
                                      searchParams.memberships.length ==
                                        memberships.length
                                  "
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index == 0">Membership</span>
                      </template>
                      <template
                          v-else
                          v-slot:selection="{ item, index }"
                      >
                        <span v-if="index == 0">{{ item.name }}</span>
                        <span v-if="index == 1">, ...</span>
                      </template>

                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="
                                      toggle('memberships', 'memberships')
                                    "
                        >
                          <v-list-item-action>
                            <v-icon
                                :color="
                                          searchParams.memberships != null &&
                                          searchParams.memberships.length > 0
                                            ? 'indigo darken-4'
                                            : ''
                                        "
                            >{{
                                getIcon("memberships", "memberships")
                              }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </div>
                </th>
                <th>
                  <div class="">Card Number</div>
                  <div class="search_column">
                    <v-text-field
                        solo
                        filled
                        label="Card Number"
                        v-model="searchParams.display_number"
                        :loading="isLoading"
                        :search-input.sync="display_number"
                        @keyup.enter="getFilterData"
                        class="q-text-field shadow-0"
                        flat
                        hide-details
                    ></v-text-field>
                  </div>
                </th>
                <th >
                  Expiry Date
                </th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="member in membersList"
                  :key="member.id"
              >
                <td >
                  {{ member.check_in | timeStamp }}
                </td>
                <td >
                  <div
                      v-if="
                              member.check_out != undefined &&
                                member.check_out != ''
                            "
                  >
                    {{ member.check_out | timeStamp }}
                  </div>
                  <div  v-else>
                    {{ "--" }}
                  </div>
                </td>
                <td
                    v-if="
                            checkReadPermission(
                              $modules.memberships.unrestricted_view.slug
                            )
                          "
                >
                  <div >
                    {{ member.scanner_user_name }}
                  </div>
                </td>
                <td >
                  <div
                      style="width: 200px;"
                      v-if="
                              checkReadPermission(
                                $modules.memberships.credential_view.slug
                              )
                            "
                  >
                    <v-btn
                        small
                        color="#00b0af"
                        class="d-inline-block text-truncate"
                        style="
                                max-width: 200px;
                                text-transform: none;
                              "
                        dark
                        @click="showUserModel(member.customer_id)"
                    >
                              <span class="text-truncate"
                              >{{ member.first_name }}
                                {{ member.last_name }}</span
                              ></v-btn
                    >
                  </div>
                  <div  v-else>
                    {{ member.first_name }}
                    {{ member.last_name }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.age }}
                  </div>
                </td>
                <td >
                  <div
                      v-if="
                              checkReadPermission(
                                $modules.memberships.management.slug
                              )
                            "
                  >
                    <v-btn
                        small
                        color="#00b0af"
                        class="d-inline-block text-truncate"
                        style="
                                max-width: 100px;
                                min-width: 100px;
                                text-transform: none;
                              "
                        dark
                        @click="viewMembership(member.membership_id)"
                    >
                              <span class="text-truncate">{{
                                  member.membership_name
                                }}</span></v-btn
                    >
                  </div>
                  <div  v-else>
                    {{ member.membership_name }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.ticket_name }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.end_date }}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="getSchedules"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>
    <schedule-customer-model
      v-bind="userModel"
      @close="userModel.userID = null"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import MembershipTopTab from "./MembershipTabs/MembershipTopTab";
import ScheduleCustomerModel from "./MembershipTabs/CustomerModel";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {
    ExportIcon,SvgIcon,
    ScheduleCustomerModel,
    MembershipTopTab,
  },
  data() {
    return {
      btnShow: false,
      membersList: [],
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      names: [],
      name: null,
      isLoading: false,
      page: 1,
      perPage: null,
      totalPages: 1,
      cashier_names: [],
      cashier_name: null,
      cashierPermission: false,
      menu1: null,
      menu2: null,
      userModel: { userID: null, type: "details" },
      searchParams: {
        memberships: [],
        from_date: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      display_numbers: [],
      display_number: null,
    };
  },

  created() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  mounted() {
    if (this.$store.getters.getMemberships.status == false) {
      this.$store.dispatch("loadMemberships");
    }
    this.getSchedules();
  },

  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    memberships() {
      return this.$store.getters.getMemberships.data;
    },
  },
  watch: {
    page() {
      this.getSchedules();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    cashier_name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("cashier_name", val);
    },
    display_number(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("display_number", val);
    },
  },
  methods: {
    gotoPage(route){
      this.$router.push(route);
    },
    ageOkClick() {
      if (this.fromAge != null && this.toAge != null) {
        this.searchParams.from_age = this.fromAge;
        this.searchParams.to_age = this.toAge;
        let customAgeRange = this.fromAge + "-" + this.toAge;
        this.AgeRange.push(customAgeRange);
        this.searchParams.age = customAgeRange;
        this.toAge = null;
        this.fromAge = null;
        this.$refs.ageRef.blur();
        this.getSchedules();
      }
    },
    ageChange() {
      if (this.searchParams.age != "All") {
        let data = this.searchParams.age.split("-");
        this.searchParams.from_age = data[0];
        this.searchParams.to_age = data[1];
        this.getSchedules();
      } else {
        this.allAge();
      }
    },
    allAge() {
      delete this.searchParams.from_age;
      delete this.searchParams.to_age;
      delete this.searchParams.age;
      this.getSchedules();
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    toggle(type, field) {
      this.$nextTick(() => {
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length == this[type].length
        ) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map(function(el) {
            return el.membership_id;
          });
        }
        this.getSchedules();
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (
        this.searchParams[field] != null &&
        this.searchParams[field].length == this[type].length
      ) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field] != null &&
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    getFilter() {
      let url =
        "&from_date=" +
        this.searchParams.from_date +
        "&to_date=" +
        this.searchParams.to_date;
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (
        typeof this.searchParams.name != "undefined" &&
        this.searchParams.name != null
      ) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.cashier_name == "All") {
        this.searchParams.cashier_name = null;
      } else if (
        typeof this.searchParams.cashier_name != "undefined" &&
        this.searchParams.cashier_name != null
      ) {
        url += "&cashier_name=" + this.searchParams.cashier_name;
      }
      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (
        this.searchParams.memberships == null ||
        this.searchParams.memberships.length == 0
      ) {
        delete this.searchParams.memberships;
      } else if (
        typeof this.searchParams.memberships != "undefined" &&
        this.searchParams.memberships.length > 0
      ) {
        url += "&memberships=" + this.searchParams.memberships.join(",");
      }
      if (
        this.searchParams.display_number == "All" ||
        this.searchParams.display_number == ""
      ) {
        this.searchParams.display_number = null;
      } else if (
        typeof this.searchParams.display_number != "undefined" &&
        this.searchParams.display_number != null
      ) {
        url += "&display_number=" + this.searchParams.display_number;
      }
      return url;
    },
    getSchedules() {
      let url = "";
      url = this.getFilter();
      this.showLoader("Loading");
      this.$http
        .get(
          "venues/memberships/attendance-log?page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            url
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.membersList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .get(
          "venues/memberships/members/filters?field=" + type + "&search=" + val
        )
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            }
            if (type == "cashier_name") {
              this.cashier_names = response.data.data;
              this.cashier_names.unshift(val);
              this.cashier_names.unshift("All");
            }
            if (type == "display_number") {
              this.display_numbers = response.data.data.card_number;
              this.display_numbers.unshift(val);
              this.display_numbers.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getFilterData() {
      this.page = 1;
      this.getSchedules();
    },

    customerForward(id) {
      this.$router.push(
        {
          name: "CustomersByID",
          params: { id: id },
        },
        () => {}
      );
    },
    viewMembership(membership_id) {
      this.$router.push(
        {
          name: "MembershipView",
          params: { data: btoa(membership_id) },
        },
        () => {}
      );
    },

    reportDownload() {
      let link = "attendance-log-excel";
      let url = "";
      url = this.getFilter();
      if (!url) return;
      this.showLoader("Downloading report");
      console.log(link);
      this.$http
        .get(`venues/memberships/` + link + `/download?${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadFile(response, "Membership_Attendance_Log_Export_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.light-blue-text{
  color: rgba(17, 42, 70, 0.8) !important;
}
</style>
