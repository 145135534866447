<template>
  <div class="d-flex p-2">
    <template>
      <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/memberships')">
        <SvgIcon :class="[dashboardClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']"
                 text="Dashboard">
          <template v-slot:icon>
            <DashboardIcon/>
          </template>
        </SvgIcon>
      </div>
      <v-divider
          style="background-color: rgba(220, 220, 220, 1)" vertical
      ></v-divider>
    </template>
    <template v-if="checkReadPermission($modules.memberships.schedule.slug)">
      <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/memberships/attendance-log')">
        <SvgIcon :class="[scheduleClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']" text="Logs">
          <template v-slot:icon>
            <SchedulesIcon/>
          </template>
        </SvgIcon>
      </div>
      <v-divider
          style="background-color: rgba(220, 220, 220, 1)" vertical
      ></v-divider>
    </template>

    <template v-if="checkReadPermission($modules.memberships.attendance.slug)">
      <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/memberships/attendance')">
        <SvgIcon :class="[scheduleNewClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']"
                 text="Attendance">
          <template v-slot:icon>
            <SchedulesIcon/>
          </template>
        </SvgIcon>
      </div>
      <v-divider
          style="background-color: rgba(220, 220, 220, 1)" vertical
      ></v-divider>
    </template>

    <template v-if="checkReadPermission($modules.memberships.analytics.slug)">
      <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/memberships/analytics')">
        <SvgIcon :class="[analyticsClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']"
                 text="Analytics">
          <template v-slot:icon>
            <LogsIcon/>
          </template>
        </SvgIcon>
      </div>
      <v-divider
          style="background-color: rgba(220, 220, 220, 1)" vertical
      ></v-divider>
    </template>

    <template v-if="checkReadPermission($modules.memberships.sales.slug)">
      <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/memberships/sales')">
        <SvgIcon :class="[salesClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']" text="Sales">
          <template v-slot:icon>
            <AnalyticsIcon/>
          </template>
        </SvgIcon>
      </div>
      <v-divider ertical style="background-color: rgba(220, 220, 220, 1)"></v-divider>
    </template>

    <template>
      <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/memberships/logs')" v-if="checkReadPermission($modules.memberships.members.slug)">
        <SvgIcon :class="[membersClass ? 'text-xl qp-tab-nav-is-active' : 'text-xl  text-thin-black']" text="Members">
          <template v-slot:icon>
            <CustomersIcon/>
          </template>
        </SvgIcon>
      </div>
      <v-divider
          style="background-color: rgba(220, 220, 220, 1)" vertical
      ></v-divider>
    </template>

    <template>
      <div class="nv_item d-flex p-4 pointer" @click="gotoConfiguration" v-if="checkWritePermission($modules.memberships.dashboard.slug)">
        <SvgIcon class="text-xl  text-thin-black " text="Configuration">
          <template v-slot:icon>
            <ConfigIcon/>
          </template>
        </SvgIcon>
      </div>
    </template>
  </div>

</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import LogsIcon from "@/assets/images/memberships/logs_icon.svg";
import DashboardIcon from "@/assets/images/memberships/dashboard-icon.svg";
import SchedulesIcon from "@/assets/images/memberships/calendar-icon.svg";
import AnalyticsIcon from "@/assets/images/memberships/analytics-up.svg";
import CustomersIcon from "@/assets/images/memberships/people-icon.svg";
import ConfigIcon from "@/assets/images/memberships/cog_icon.svg";

export default {
  components: {
    SvgIcon, ConfigIcon, LogsIcon, DashboardIcon, SchedulesIcon, AnalyticsIcon, CustomersIcon,
  },
  props: {
    dashboardClass: {type: Boolean, default: false},
    scheduleClass: {type: Boolean, default: false},
    scheduleNewClass: {type: Boolean, default: false},
    analyticsClass: {type: Boolean, default: false},
    salesClass: {type: Boolean, default: false},
    membersClass: {type: Boolean, default: false},

  },
  data() {
    return {
      btnShow: false,

    };
  },
  methods: {
    gotoPage(route) {
      this.$router.push(route);
    },
    gotoConfiguration() {
      this.$router.push({
        name: "MembershipConfiguration",
      });
    },
  },
};
</script>