<template>
  <div>
    <v-dialog
      v-model="userModel"
      width="800"
      persistent
      @input="$emit('close')"
    >
      <v-card>
        <v-card-actions>
          <SvgIcon class="text-2xl font-semibold" :text="`${customerDetails.first_name || '' } ${ customerDetails.last_name || ''}` " >
          </SvgIcon>
        </v-card-actions>
        <v-card-text>
          <v-row dense>
            <div class="col-md-4 pr-0 mr-0">
              <view-image
                  :image="customerDetails.profile_image"
                  :defaultImage="'user'"
                  :height="250"
                  style="border-radius: 6px; max-width: 95%; max-height: 250px;"
                  class="img-fluid dynamic-image"
              ></view-image>
            </div>
            <div class="col-md-8 ml-0 pl-0">
              <table class="table blue-text" style="border-spacing: 12px">
                <tr >
                  <td>Name <span style="display: block" class="font-semibold blue-text">{{ customerDetails.first_name || "" }} {{ customerDetails.last_name || "" }}</span></td>
                  <td>Mobile <span style="display: block" class="font-semibold blue-text">{{ customerDetails.mobile || "--" }}</span></td>
                  <td>Email <span style="display: block" class="font-semibold blue-text"> {{ customerDetails.email || "--" }}</span></td>
                </tr>

                <tr>
                  <td>Date Of Birth <span style="display: block" class="font-semibold blue-text">{{ customerDetails.dob || '--' }}  <span v-if="customerDetails.age ">({{ customerDetails.age }})</span>   </span></td>
                  <td>Nationality <span style="display: block" class="font-semibold blue-text">{{ customerDetails.nationality || "--" }} </span></td>
                  <td>Gender <span style="display: block" class="font-semibold blue-text">{{ customerDetails.gender || "--" }}</span></td>
                </tr>
                <tr>
                  <td>Weight <span style="display: block" class="font-semibold blue-text">{{ customerDetails.weight || "--" }}</span></td>
                  <td>Height <span style="display: block" class="font-semibold blue-text">{{ customerDetails.height || "--" }}</span></td>
                  <td>Joining Date <span style="display: block" class="font-semibold blue-text"> {{ customerDetails.join_date | dayFormat }}</span></td>
                </tr>
                <tr v-if="customerDetails.venue_customer_tags && customerDetails.venue_customer_tags.length">
                  <td colspan="3">Tags <div style="display: block">
                    <v-btn x-small
                           class="mr-2"
                           v-for="(customerTag,
                            index) in customerDetails.venue_customer_tags"
                           :key="index"
                           :color="customerTag.tag.color_code"   >
                      {{ customerTag.tag.name }}
                    </v-btn>

                  </div>   </td>
                </tr>
              </table>
            </div>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              v-if="customerDetails.memberships.length"
              @click="membershipDialoge = true"
          >Membership</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 "
            text
            @click="closeAll"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="membershipDialoge" width="400">
      <v-card>
        <v-card-text v-if="activeMembership.length > 0">
          <v-list v-for="(membership, index) in activeMembership" :key="index">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-wallet-membership</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
                >Membership</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{
                  typeof membership.membership_name != "undefined" &&
                  membership.membership_name != null
                    ? membership.membership_name
                    : "NA"
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-package</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
                >Package</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{
                  typeof membership.package_name != "undefined" &&
                  membership.package_name != null
                    ? membership.package_name
                    : "NA"
                }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-card-text>
        <v-card-text v-else class="pa-8 text-center">
          <h3>No memberships found!</h3>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ptDialoge" width="400">
      <v-card>
        <v-card-text
          v-if="customerDetails.trainers && customerDetails.trainers.length > 0"
        >
          <v-list
            v-for="(trainer, index) in customerDetails.trainers"
            :key="index"
          >
            <v-list-item>
              <v-list-item-icon>
                <div class="personal-trainer_ic"></div>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
                >Personal Trainer</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{ trainer.trainer_name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-package-variant-closed</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
                >Training Package</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{ trainer.package_name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-wallet</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
                >Remaining Sessions</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{ trainer.remaining_sessions }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>

          <div class="d-flex justify-center mt-4">
            <v-btn
              @click="
                historyType = 5;
                customerHistory();
              "
              small
              color="#066a8c"
              dark
              >History</v-btn
            >
          </div>
        </v-card-text>
        <v-card-text v-else class="pa-8 text-center">
          <h3>No personal trainer found!</h3>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="historyDialoge"
      width="900"
      persistent
      scrollable
      @input="$emit('close')"
    >
      <v-card>
        <v-card-title class="headline">History</v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col md="3">
                <v-select
                  v-model="historyType"
                  :items="[{ id: 0, name: 'All' }, ...productTypes]"
                  label="Select Type"
                  dense
                  item-value="id"
                  item-text="name"
                  @change="customerHistory()"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row no-gutters style="margin-top: -20px">
              <table class="history_table">
                <thead>
                  <th>Timestamp</th>
                  <th>Type</th>
                  <th>Order No</th>
                  <th>Due Date</th>
                  <th>Price</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Receipt</th>
                </thead>

                <tr
                  v-for="(data, index) in historyData"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ data.timestamp | timeStamp }}</td>
                  <td>
                    {{ data.product_type }}
                  </td>
                  <td>
                    {{
                      data.order_seq_no
                        ? data.order_seq_no
                        : data.invoice_seq_no
                    }}
                  </td>
                  <td>{{ data.order_date | dateformat }}</td>
                  <td>{{ Number(Math.abs(data.price)) | toCurrency }}</td>
                  <td>{{ Number(Math.abs(data.total)) | toCurrency }}</td>
                  <td>
                    {{ data.status }}
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <v-btn
                        normal
                        small
                        @click="getOrderDetails(data.order_id)"
                        >{{
                          data.status == "Unpaid" ? "Invoice" : "Receipt"
                        }}</v-btn
                      >
                    </div>
                  </td>
                </tr>
              </table>

              <v-card-text
                v-if="historyData && historyData.length == 0"
                class="pa-8 text-center"
              >
                <h3>No History found!</h3>
              </v-card-text>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="
              () => {
                historyDialoge = false;
                closeEdit();
              }
            "
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="creditDialog" width="900">
      <v-card>
        <v-card-title class="headline"> Credit History </v-card-title>
        <v-card-text v-if="creditList && creditList.length > 0">
          <v-container>
            <v-row>
              <table class="history_table">
                <thead>
                  <th>Timestamp</th>
                  <th>Receipt ID</th>
                  <th>Receipt Amount</th>
                  <th>Type</th>
                  <th>Service</th>
                  <th>Payment Method</th>
                  <th>Receipt</th>
                </thead>

                <tr
                  v-for="(credit, index) in creditList"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ credit.timestamp | timeStamp }}</td>
                  <td>
                    {{ credit.order_seq_no }}
                  </td>
                  <td>
                    {{ credit.total }}
                  </td>
                  <td>{{ credit.product_type }}</td>
                  <td>{{ credit.service_name }}</td>
                  <td>
                    {{ credit.payment_method_name }}
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <v-btn
                        normal
                        small
                        v-if="credit.status_id != 2"
                        @click="getOrderDetails(credit.order_id)"
                        >Receipt</v-btn
                      >
                    </div>
                  </td>
                </tr>
              </table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text
          v-else
          style="height: 300px"
          class="pa-8 d-flex align-center justify-center"
        >
          <h3>No credit found!</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="creditDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" width="700" @input="closeEdit">
      <v-form ref="form" v-model="valid">
        <v-card-actions class="headline">
          <v-row>
            <v-col md="6">
              <div class="head">Edit Customer</div>
            </v-col>
            <v-col md="6" class="text-right d-flex flex-row-reverse">
              <card-data-button
                class="mt-4"
                label="HID Omnikey"
                @data="
                  (data) => {
                    setCardData(data);
                  }
                "
              ></card-data-button>
              <card-reader-button
                class="mt-4"
                label="Samsotech Reader"
                @data="
                  (data) => {
                    setCardData(data);
                  }
                "
              ></card-reader-button>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card>
          <v-card-text class="form_bg">
            <v-container>
              <v-row justify="center">
                <v-col md="6">
                  <image-upload
                    @upload="
                      (data) => {
                        customerDetails.image = data;
                      }
                    "
                    @remove="
                      () => {
                        customerDetails.profile_image = null;
                      }
                    "
                    :image_path="customerDetails.profile_image"
                    :height="200"
                  ></image-upload>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-switch
                    style="float: right"
                    v-model="customerDetails.opt_marketing"
                    label="Opt In Marketing"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <input type="hidden" v-model="customerDetails.id" />
                  <v-text-field
                    v-model="customerDetails.first_name"
                    outlined
                    background-color="#fff"
                    label="First Name*"
                    required
                    :rules="[(v) => !!v || 'First name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <input type="hidden" v-model="customerDetails.id" />
                  <v-text-field
                    v-model="customerDetails.last_name"
                    outlined
                    background-color="#fff"
                    label="Last Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <mobile-field
                    v-model="customerDetails.mobile"
                    label="Phone*"
                    required
                    :refresh-country="refreshCountry"
                  ></mobile-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="customerDetails.email"
                    label="Email*"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[
                      (v) => !!v || 'Email is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <date-of-birth v-model="customerDetails.dob"> </date-of-birth>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="customerDetails.country_id"
                    :items="countries"
                    label="Nationality"
                    item-value="id"
                    item-text="name"
                    outlined
                    background-color="#fff"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :hint="`ID Type`"
                    :label="`ID Type`"
                    :rules="idTypeRule"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-value="id"
                    item-text="name"
                    :items="idProofTypes"
                    v-model="customerDetails.id_proof_type_id"
                    @change="changeIdProofTypeId"
                    background-color="#fff"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col md="7">
                      <v-text-field
                        :hint="`ID Number`"
                        :label="`ID Number`"
                        :rules="idTypeRule"
                        class="text_field1"
                        outlined
                        v-model="customerDetails.id_proof_number"
                        background-color="#fff"
                      ></v-text-field>
                    </v-col>
                    <v-col md="5">
                      <v-file-input
                        v-model="customerDetails.id_proof"
                        class="text_field2"
                        :placeholder="
                          `${
                            customerDetails.id_proof_path ? 'Change' : 'Select'
                          }`
                        "
                        :label="
                          `${
                            customerDetails.id_proof_path
                              ? 'Download'
                              : 'ID Proof'
                          }`
                        "
                        :rules="idProofRule"
                        prepend-icon=""
                        background-color="#fff"
                        outlined
                      >
                        <template v-slot:prepend-inner>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                color="cyan"
                                v-if="customerDetails.id_proof_path"
                                @click="openFile(customerDetails.id_proof_path)"
                                v-on="on"
                              >
                                mdi-download-box
                              </v-icon>
                              <v-icon v-else v-on="on">
                                mdi-card-account-details
                              </v-icon>
                            </template>
                            <span v-if="customerDetails.id_proof_path"
                              >Download uploaded file</span
                            >
                            <span v-else>Upload ID Proof</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index == 0"
                            color="cyan accent-4"
                            dark
                            label
                            small
                          >
                            <span style="width: 38px" class="text-truncate">{{
                              text
                            }}</span>
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <!-- <div style="margin-top: -110px"></div> -->
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="customerDetails.gender"
                    :items="['Male', 'Female']"
                    label="Gender"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="closeEdit"
              >Close</v-btn
            >
            <v-btn
              color=" darken-1"
              class="ma-2 white--text teal-color"
              @click="saveCustomer"
              text
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="scheduleDialog" width="70%">
      <v-card>
        <v-card-title class="headline">
          Schedule History <v-spacer></v-spacer>
          <v-btn @click="exportSchedules">Export</v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12">
                <div class="d-flex justify-center">
                  <v-btn-toggle
                    borderless
                    class="date_button_navigation"
                    v-model="schedulesFilter.type"
                    mandatory
                    width="100%"
                    @change="getScheduleList"
                    dense
                  >
                    <v-btn value="facility">Facility</v-btn>
                    <v-btn value="training">Training</v-btn>
                    <v-btn value="academy">Academy</v-btn>
                    <v-btn value="event">Events</v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>
              <v-col md="12">
                <table class="history_table">
                  <thead>
                    <th v-if="schedulesFilter.type == 'academy'">
                      Academy | Program
                    </th>
                    <th v-else-if="schedulesFilter.type == 'training'">
                      Package
                    </th>
                    <th v-else-if="schedulesFilter.type == 'event'">
                      Event Name
                    </th>
                    <th @click="sortColumn('sort_order')">
                      Due Date
                      <v-icon class="ml-2" color="teal" small>
                        mdi-sort-{{
                          schedulesFilter.sort_order == "asc"
                            ? "ascending"
                            : "descending"
                        }}
                      </v-icon>
                    </th>
                    <th>Time</th>
                    <th>Facility</th>
                    <th
                      v-if="
                        schedulesFilter.type == 'academy' ||
                          schedulesFilter.type == 'training'
                      "
                    >
                      Trainer
                    </th>
                    <th>Service</th>
                    <th style="width: 110px">
                      Status

                      <v-select
                        dense
                        filled
                        @change="getScheduleList"
                        style="width: 100px"
                        item-text="name"
                        item-value="id"
                        v-model="schedulesFilter.status"
                        :items="getScheduleStatus()"
                      ></v-select>
                    </th>
                    <th>Details</th>
                    <th>Action</th>
                  </thead>
                  <tbody v-if="scheduleLog && scheduleLog.length > 0">
                    <tr
                      v-for="(schedule, index) in scheduleLog"
                      :key="index"
                      class="text-center"
                    >
                      <td v-if="schedulesFilter.type == 'academy'">
                        {{ schedule.workshop_name }} <br />
                        {{ schedule.program_name }}
                      </td>
                      <td v-else-if="schedulesFilter.type == 'training'">
                        {{ schedule.package_name }}
                      </td>
                      <td v-else-if="schedulesFilter.type == 'event'">
                        {{ schedule.event_name }}
                      </td>
                      <td>
                        {{ schedule.date | dateformat }}
                      </td>
                      <td>
                        {{ schedule.start_time | timeFormat }} to
                        {{ schedule.end_time | timeFormat }}
                      </td>
                      <td>
                        {{ schedule.facility_name }}
                      </td>
                      <td
                        v-if="
                          schedulesFilter.type == 'academy' ||
                            schedulesFilter.type == 'training'
                        "
                      >
                        {{ schedule.trainer_name || "NA" }}
                      </td>
                      <td>{{ schedule.service }}</td>
                      <td>{{ schedule.status }}</td>
                      <td>
                        <v-btn
                          v-if="![8, 13, 19].includes(schedule.order_status_id)"
                          small
                          @click="gotoSchedule(index)"
                          >View Schedule</v-btn
                        >
                      </td>
                      <td
                        v-if="
                          schedule.status_id == 4 &&
                            schedule.order_status_id &&
                            (schedule.order_status_id == 11 ||
                              schedule.order_status_id == 12 ||
                              schedule.order_status_id == 18)
                        "
                      >
                        <v-btn small @click="refund(schedule)">Refund</v-btn>
                      </td>
                      <td v-else>NA</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        :colspan="schedulesFilter.type == 'facility' ? 8 : 9"
                        class="text-center"
                      >
                        No records
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-container>
          <v-pagination
            class="mt-2"
            v-model="page"
            :length="totalPages"
          ></v-pagination>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="scheduleDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>

    <refund
      :repeatRefundAmount="repeatRefundAmount"
      :repeatBookingdata="repeatBookingdata"
      :workshopRefundAmount="workshopRefundAmount"
      :workshopCustomerAttendanceId="workshopCustomerAttendanceId"
      v-if="refund_dialog"
      :show="refund_dialog"
      @close="refund_dialog = false"
      @refunded="
        refund_dialog = false;
        getScheduleList();
      "
    ></refund>

    <order-details
      :id="orderId"
      @close="orderId = null"
      @paymentDone="orderAction"
    ></order-details>
  </div>
</template>

<script>
// import moment from "moment";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import refund from "@/components/Order/Refund.vue";
import bookingFields from "@/mixins/bookingFieldValidation";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {
    SvgIcon,
    OrderDetails,
    refund,
  },
  props: {
    userID: { type: Number, default: null },
    type: { type: String, default: "details" },
    isPermissionCheck: { type: Boolean, default: true },
  },
  mixins: [bookingFields],
  data() {
    return {
      refund_dialog: false,
      workshopRefundAmount: null,
      workshopCustomerAttendanceId: null,
      repeatRefundAmount: null,
      repeatBookingdata: null,
      userModel: false,
      customerDetails: { trainers: [], memberships: [], opt_marketing: false },
      historyData: [],
      historyType: null,
      membershipDialoge: false,
      ptDialoge: false,
      historyDialoge: false,
      image: null,
      menu: false,
      editDialog: false,
      valid: false,
      creditDialog: false,
      creditList: [],
      page: 1,
      totalPages: 1,
      orderId: null,
      scheduleLog: [],
      scheduleDialog: false,
      schedulesFilter: {
        sort_order2: "desc",
        sort_order: "asc",
        latest: true,
        status: null,
        type: "facility",
      },
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      refreshCountry: false,
      customerNote: null,
    };
  },
  watch: {
    userID(val) {
      if (val != null) {
        if (this.type == "history") {
          this.customerHistory();
        } else {
          this.getCustomerDetails();
        }
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    page: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.creditDialog) {
            this.getCreditList();
          } else if (this.historyDialoge) {
            this.customerHistory();
          } else if (this.scheduleDialog) {
            this.getScheduleList();
          }
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  computed: {
    activeMembership() {
      let data = this.customerDetails.memberships.filter(
        (item) => item.status == 1
      );
      if (data.length == 0 && this.customerDetails.memberships.length > 0) {
        return [
          this.customerDetails.memberships[
            this.customerDetails.memberships.length - 1
          ],
        ];
      }
      return [];
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueCode() {
      return this.$store.getters.venueInfo.code.toUpperCase();
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
  },
  methods: {
    getCustomerDetails() {
      this.$http
        .get("venues/customers/" + this.userID)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.customerDetails = response.data.data;
            this.customerNote = response.data.data
              ? response.data.data.notes
              : null;
            if (response.data.data.opt_marketing == 1) {
              this.customerDetails.opt_marketing = true;
            } else {
              this.customerDetails.opt_marketing = false;
            }
            if (this.type == "edit") {
              this.showEdit();
              if (this.customerDetails.customer_documents) {
                if (
                  this.customerDetails.customer_documents[0] &&
                  this.customerDetails.customer_documents[0].id_proof_type_id
                ) {
                  this.$set(
                    this.customerDetails,
                    "id_proof_type_id",
                    this.customerDetails.customer_documents[0].id_proof_type_id
                  );
                }
                if (
                  this.customerDetails.customer_documents[0] &&
                  this.customerDetails.customer_documents[0].id_proof_number
                ) {
                  this.$set(
                    this.customerDetails,
                    "id_proof_number",
                    this.customerDetails.customer_documents[0].id_proof_number
                  );
                }
                if (
                  this.customerDetails.customer_documents[0] &&
                  this.customerDetails.customer_documents[0].id_proof_path
                ) {
                  this.$set(
                    this.customerDetails,
                    "id_proof_path",
                    this.customerDetails.customer_documents[0].id_proof_path
                  );
                }
              }
            } else {
              this.userModel = true;
              this.refreshCountry = !this.refreshCountry;
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    orderAction() {
      this.$emit("orderAction");
      this.$emit("confirm");
      this.closeAll();
    },
    closeAll() {
      this.historyDialoge = false;
      this.userModel = false;
      this.membershipDialoge = false;
      this.ptDialoge = false;
      this.historyDialoge = false;
      this.editDialog = false;
      this.valid = false;
      this.creditDialog = false;
      this.page = 1;
      this.totalPages = 1;
      this.orderId = null;
      this.scheduleLog = [];
      this.scheduleDialog = false;
      this.schedulesFilter = {
        sort_order2: "desc",
        sort_order: "asc",
        latest: true,
        status: null,
      };
      this.$emit("close");
    },
    customerHistory() {
      var params = "";
      if (this.historyType) {
        params = "?product_type_id=" + this.historyType;
      }
      this.historyData = [];
      this.$http
        .get("venues/customers/history/" + this.userID + params)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.historyData = response.data.data;
            this.historyDialoge = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getOrderDetails(id) {
      this.orderId = id;
    },
    allHistory() {
      this.historyType = null;
      this.historyLog();
    },
    getPersonalTrainingHistory() {
      this.historyType = "PERSONALTRAINING";
      this.historyLog();
    },
    closeEdit() {
      this.editDialog = false;
      if (this.type == "details") {
        this.userModel = true;
        this.getCustomerDetails();
      } else if (this.type == "history") {
        this.$emit("close");
      } else {
        this.image = null;
        this.$emit("close");
        this.$refs.form.resetValidation();
      }
    },
    saveCustomer() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      for (let key in this.customerDetails) {
        if (this.customerDetails[key]) {
          formData.append(key, this.customerDetails[key]);
        }
      }
      if (
        !this.customerDetails["last_name"] ||
        this.customerDetails["last_name"] == null
      ) {
        formData.append("last_name", "");
      }
      formData.append("opt_marketing", this.customerDetails.opt_marketing);
      this.userID = this.customerDetails.id;
      this.$http({
        method: "post",
        data: formData,
        url: "venues/customers/" + this.customerDetails.id,
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.editDialog = false;
            this.showSuccess("Customer Details Updated.");
            this.closeEdit();
            this.$emit("updated");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showEdit() {
      if (this.$store.getters.getCountries.status == false) {
        this.$store.dispatch("loadCountries");
      }

      if (this.customerDetails.image) {
        this.image = this.s3BucketURL + this.customerDetails.image;
      }
      if (this.type == "details") {
        this.userModel = false;
      }
      this.editDialog = true;
    },
    getCreditList() {
      let userId = this.userID == null ? this.customerDetails.id : this.userID;
      this.$http
        .get(
          `venues/sales?status_id=4&order_by=created_at&order_by_value=DESC&customer_id=${userId}&credit`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.creditList = response.data.data;
            this.creditDialog = true;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    backToCreditList() {
      this.creditDialog = true;
    },
    sortColumn(column) {
      this.schedulesFilter.latest = false;
      this.schedulesFilter[column] =
        this.schedulesFilter[column] == "desc" ? "asc" : "desc";
      this.getScheduleList();
    },
    getScheduleList(param = null) {
      let userId = this.userID == null ? this.customerDetails.id : this.userID;
      let url = `venues/customers/schedules/${userId}?page=${this.page}&sort_order=${this.schedulesFilter.sort_order}`;
      if (this.schedulesFilter.latest) {
        url += "&latest=true";
      }
      if (this.schedulesFilter.status) {
        url += `&status=${this.schedulesFilter.status}`;
      }
      let type = this.schedulesFilter.type
        ? this.schedulesFilter.type
        : "facility";
      url += `&type=${type}`;
      this.showLoader("Loading");
      this.$http
        .get(url)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.scheduleLog = response.data.data;
            if (param == "schedule_history") {
              this.scheduleDialog = true;
            }
            this.totalPages = response.data.total_pages;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    gotoSchedule(index) {
      let data = this.scheduleLog[index];
      let venue_service = this.venueServices.find(
        (x) => x.venue_service_id == data.venue_service_id
      );
      if (this.schedulesFilter.type == "facility") {
        if (this.$route.name != "ScheduleWithParams") {
          this.$router.push({
            name: "ScheduleWithParams",
            params: {
              data: btoa(
                JSON.stringify({
                  venue_service: venue_service,
                  date: data.date,
                  order_id: data.order_id,
                })
              ),
            },
          });
        }
      } else if (this.schedulesFilter.type == "event") {
        if (this.$route.name != "EventsView") {
          this.$router.push({
            name: "EventsView",
            params: { data: btoa(data.event_id) },
          });
        }
      } else if (this.schedulesFilter.type == "training") {
        if (this.$route.name != "TrainerCustomers") {
          this.$router.push({
            name: "TrainerCustomers",
            params: { data: btoa(data.trainer_id) },
          });
        } else {
          this.scheduleDialog = false;
          this.userModel = false;
          this.$emit("close");
        }
      } else if (this.schedulesFilter.type == "academy") {
        if (this.$route.name != "WorkshopView") {
          this.$router.push({
            name: "WorkshopView",
            params: {
              data: btoa(
                JSON.stringify({
                  id: data.workshop_id,
                  date: data.date,
                  workshop_schedule_id: data.workshop_schedule_id,
                  workshop_program_id: data.workshop_program_id,
                })
              ),
            },
          });
        }
        if (this.$route.name == "WorkshopView") {
          this.$router.go(
            this.$router.push({
              name: "WorkshopView",
              params: {
                data: btoa(
                  JSON.stringify({
                    id: data.workshop_id,
                    date: data.date,
                    workshop_schedule_id: data.workshop_schedule_id,
                    workshop_program_id: data.workshop_program_id,
                  })
                ),
              },
            })
          );
        }
      }
    },
    exportSchedules() {
      let userId = this.userID == null ? this.customerDetails.id : this.userID;
      let url = `venues/customers/schedules/export/${userId}?page=${this.page}&sort_order=${this.schedulesFilter.sort_order}`;
      if (this.schedulesFilter.latest) {
        url += "&latest=true";
      }
      if (this.schedulesFilter.status) {
        url += `&status=${this.schedulesFilter.status}`;
      }
      let type = this.schedulesFilter.type
        ? this.schedulesFilter.type
        : "facility";
      url += `&type=${type}`;
      this.showLoader("Downloading..");
      this.$http
        .get(url, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.downloadFile(
              response,
              `${this.customerDetails.first_name} ${this.customerDetails
                .last_name || ""} Schedules`,
              "pdf"
            );
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    refund(schedule) {
      this.confirmModel = {
        id: schedule.id,
        title: `Do you want refund this schedule?`,
        description: `This will refund selected schedule. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: this.schedulesFilter.type,
        data: schedule,
      };
    },

    confirmActions(data) {
      this.payments = [
        {
          card_type_id: null,
          payment_code: null,
          payment_method_id: null,
          amount: null,
          payment_method: null,
        },
      ];

      this.$store.commit("setOrderPayments", this.payments);

      if (data.type == "academy") {
        if (data.id && data.data) {
          this.workshopCustomerAttendanceId = data.id;
          this.showLoader("Loading...");

          this.$http
            .get(
              `venues/workshops/schedules/booking/single-class-refund-amount/${data.data.order_id}`
            )
            .then((response) => {
              if (response.status == 200 && response.data.status) {
                this.hideLoader();
                if (response.data.type == "partial") {
                  this.workshopRefundAmount = response.data.refund_amount;
                  this.refund_dialog = true;
                } else if (response.data.type == "normal") {
                  this.$store
                    .dispatch("loadOrderDetails", data.data.order_id)
                    .then((response) => {
                      if (response.status == 200) {
                        this.refund_dialog = true;
                      }
                    });
                }
              }
            })
            .catch((error) => {
              this.customersLogDialog = false;
              this.errorChecker(error);
            });
        }
      } else if (data.type == "facility") {
        if (data.data.facility_booking_repeat_id) {
          var obj = {
            booking_id: data.data.id,
            date: data.data.date,
            start_time: data.data.start_time,
            end_time: data.data.end_time,
            order_id: data.data.order_id,
            facility_id: data.data.facility_id,
            status_id: data.data.facility_status_id,
          };
          this.showLoader();
          this.$http
            .post(`venues/facilities/bookings/repeat/check-refund-amount`, {
              booking_ids: [obj],
            })
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.hideLoader();
                if (response.data.total_amount) {
                  this.repeatRefundAmount = response.data.total_amount;
                  this.repeatBookingdata = [obj];
                  this.refund_dialog = true;
                }
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
        } else {
          this.$store
            .dispatch("loadOrderDetails", data.data.order_id)
            .then((response) => {
              if (response.status == 200) {
                this.refund_dialog = true;
              }
            });
        }
      } else if (data.type == "training") {
        this.showLoader("Loading...");
        var formData = new FormData();
        formData.append("training_session_id", data.data.id);
        this.$http
          .post(`venues/trainers/customers/sessions/refund`, formData)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.getScheduleList();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      } else if (this.schedulesFilter.type == "event") {
        this.$store
          .dispatch("loadOrderDetails", data.data.order_id)
          .then((response) => {
            if (response.status == 200) {
              this.refund_dialog = true;
            }
          });
      } else if (data.type == "Deactivate" || data.type == "Activate") {
        this.removeCustomer(data);
      }
      this.confirmModel.id = null;
    },

    removeCustomer(data) {
      this.showLoader();
      this.$http
        .delete(`venues/customers/${this.customerDetails.id}`)
        .then((res) => {
          if (res.status == 200) {
            this.showSuccess(`Customer ${data.type} Successfully.`);
            this.hideLoader();
            this.getCustomerDetails();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    getScheduleStatus() {
      if (this.schedulesFilter.type == "facility") {
        return [
          { id: null, name: "All" },
          { id: 11, name: "Pending" },
          { id: 12, name: "Completed" },
          { id: 13, name: "Cancelled" },
          { id: 19, name: "Partial Cancel" },
          { id: 18, name: "Partial Refund" },
        ];
      } else if (this.schedulesFilter.type == "event") {
        return [
          { id: null, name: "All" },
          { id: 11, name: "Pending" },
          { id: 12, name: "Completed" },
        ];
      } else if (
        this.schedulesFilter.type == "academy" ||
        this.schedulesFilter.type == "training"
      ) {
        return [
          { id: null, name: "All" },
          { id: 1, name: "Attended" },
          { id: 2, name: "No Show" },
          { id: 0, name: "NA" },
        ];
      }
    },

    updateNote() {
      if (this.customerNote) {
        let data = {
          notes: this.customerNote,
        };
        this.showLoader();
        this.$http
          .post("venues/customers/note/" + this.customerDetails.id, data)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.showSuccess("Customer note updated!");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },

    deactivateConfirmModal() {
      let type =
        this.customerDetails.status_id == 1 ? "Deactivate" : "Activate";
      this.confirmModel = {
        id: this.customerDetails.id,
        title: `Do you want to ${type} this customer?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },
    setCardData(data) {
      if (data.id_proof_type_id) {
        this.$set(
          this.customerDetails,
          "id_proof_type_id",
          data.id_proof_type_id
        );
      }
      if (data.id_proof_number) {
        this.$set(
          this.customerDetails,
          "id_proof_number",
          data.id_proof_number
        );
      }
      if (data.id_proof_path) {
        this.$set(this.customerDetails, "id_proof_path", data.id_proof_path);
      }
      if (data.id_proof) {
        this.$set(this.customerDetails, "id_proof", data.id_proof);
      }
      if (data.dob) {
        this.$set(this.customerDetails, "dob", data.dob);
      }
      if (data.gender) {
        this.$set(this.customerDetails, "gender", data.gender);
      }
      if (data.country_id) {
        this.$set(this.customerDetails, "country_id", data.country_id);
      }
    },
    changeIdProofTypeId() {
      if (
        this.customerDetails.customer_documents &&
        this.customerDetails.customer_documents.length
      ) {
        let objType = this.customerDetails.customer_documents.find((x) => {
          return x.id_proof_type_id === this.customerDetails.id_proof_type_id;
        });
        if (typeof objType !== "undefined" && objType.id_proof_type_id) {
          this.customerDetails.id_proof_number = objType.id_proof_number;
          this.customerDetails.id_proof_path = objType.id_proof_path;
        } else {
          this.customerDetails.id_proof_number = null;
          this.customerDetails.id_proof_path = null;
        }
      } else {
        console.log("document length 0");
      }
    },
  },
};
</script>

<style scoped>
.personal-trainer_ic {
  background-image: url("../../../assets/images/nav_icon/personal-trainer-grey.png");
  padding: 10px;
  background-size: contain;
}

.credit_table table thead tr th {
  background: #066a8c !important;
  color: #fff !important;
}
.history_table thead {
  background-color: #ccc;
}
.history_table thead th {
  padding: 5px;
}
</style>
